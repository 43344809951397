import React from "react";
import Layout from "../../components/layout/layout";
import SEO from "../../components/layout/seo";
import ExpertTerms from "../../components/expertTerms";

export default function ExpertTermsPage() {
  return (
    <Layout>
      <SEO title="Expert Terms Page" />
      <ExpertTerms />
    </Layout>
  );
}
