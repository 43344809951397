import React from "react";
import ReactHtmlParser from "react-html-parser";
export default function ExpertTermsContent({
  expert_terms_of_service,
  use_of_the_platform,
  contributions_by_medical_practitioners,
  your_response_to_medicalrelated_questions,
  rules,
  proprietary_rights,
  your_license_to_the_provider,
  the_providers_licenses_to_you,
  external_links,
  indemnity_disclaimers,
  general_terms,
}) {
  const converthtml = function (input) {
    var ret = input.replace(/&gt;/g, ">");
    ret = ret.replace(/&lt;/g, "<");
    ret = ret.replace(/&quot;/g, '"');
    ret = ret.replace(/&apos;/g, "'");
    ret = ret.replace(/&amp;/g, "&");
    ret = ret.replace(/&nbsp;/g, "");

    return ret;
  };
  return (
    <div className="col-12">
      {ReactHtmlParser(converthtml(expert_terms_of_service))}
      {ReactHtmlParser(converthtml(use_of_the_platform))}
      {ReactHtmlParser(converthtml(contributions_by_medical_practitioners))}

      {ReactHtmlParser(converthtml(your_response_to_medicalrelated_questions))}
      {ReactHtmlParser(converthtml(rules))}
      {ReactHtmlParser(converthtml(proprietary_rights))}

      {ReactHtmlParser(converthtml(your_license_to_the_provider))}
      {ReactHtmlParser(converthtml(the_providers_licenses_to_you))}
      {ReactHtmlParser(converthtml(external_links))}

      {ReactHtmlParser(converthtml(indemnity_disclaimers))}
      {ReactHtmlParser(converthtml(general_terms))}
    </div>
  );
}
