import React from "react";
import "./assets/style.scss";
import ExpertTermsContent from "./expertTermsContent";
import { useStaticQuery, graphql } from "gatsby";
export default function ExpertTerms() {
  const data = useStaticQuery(graphql`
    {
      allCosmicjsExpertTermsOfServices {
        edges {
          node {
            slug
            metadata {
              expert_terms_of_service
              use_of_the_platform
              contributions_by_medical_practitioners
              your_response_to_medicalrelated_questions
              rules
              proprietary_rights
              your_license_to_the_provider
              the_providers_licenses_to_you
              external_links
              indemnity_disclaimers
              general_terms
            }
            slug
          }
        }
      }
    }
  `);

  var content_term_expert;

  data.allCosmicjsExpertTermsOfServices.edges.map((x, y) => {
    switch (x.node.slug) {
      case "expert-terms-of-service":
        content_term_expert = x.node.metadata;
        break;
    }
  });
  return (
    <div className="terms-detail">
      <div className="content">
        <div className="row">
          <ExpertTermsContent
            expert_terms_of_service={
              content_term_expert.expert_terms_of_service
            }
            use_of_the_platform={content_term_expert.use_of_the_platform}
            contributions_by_medical_practitioners={
              content_term_expert.contributions_by_medical_practitioners
            }
            your_response_to_medicalrelated_questions={
              content_term_expert.your_response_to_medicalrelated_questions
            }
            rules={content_term_expert.rules}
            proprietary_rights={content_term_expert.proprietary_rights}
            your_license_to_the_provider={
              content_term_expert.your_license_to_the_provider
            }
            the_providers_licenses_to_you={
              content_term_expert.the_providers_licenses_to_you
            }
            external_links={content_term_expert.external_links}
            indemnity_disclaimers={content_term_expert.indemnity_disclaimers}
            general_terms={content_term_expert.general_terms}
          />
        </div>
      </div>
    </div>
  );
}
